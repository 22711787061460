<template>
  <div class="gallery">
    <GalleryC/>
  </div>
</template>

<script>
import GalleryC from '../components/Gallery/GalleryC.vue'
export default {
  name: "Gallery",
  components: {
    GalleryC
  }
}
</script>

<style>


</style>