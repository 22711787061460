<template>
  <div class="gallerycomponent">
    <!-- Title -->
    <div class="headergallery">
      <div class="">
        <h1 class="">
          {{ $t("gallery.title") }}
        </h1>
      </div>
    </div>
    <!-- Images -->
    <b-card-group columns>
      <b-card
        :img-src="require(`../../assets/fotos/1-largo.jpg`)"
        img-alt="desayuno breakfast"
        overlay
      >
        <!-- <b-card-text>
          This is a wider card with supporting text below as a natural lead-in
          to additional content. This content is a little bit longer.
        </b-card-text> -->
      </b-card>
      <b-card
        :img-src="require(`../../assets/fotos/5-ancho.jpg`)"
        img-alt="Image"
        overlay
      ></b-card>
      
      <b-card
        :img-src="require(`../../assets/fotos/second.jpg`)"
        img-alt="Image"
        overlay
      ></b-card>
<b-card class="card " text-variant="white">
        <blockquote class="card-blockquote">
          <p style="color: #fff; font-size:20px; font-weight: bold;">Mosoq Inn</p>
          <footer>
            <p>"{{ $t("home.info.frase") }}"</p>
          </footer>
        </blockquote>
      </b-card>
      <b-card
        :img-src="require(`../../assets/fotos/calle.jpg`)"
        img-alt="Image"
        overlay
      ></b-card>

      <b-card
        :img-src="require(`../../assets/fotos/2-largo.jpg`)"
        img-alt="Image"
        overlay
      ></b-card>
      
      <b-card
        :img-src="require(`../../assets/fotos/4-ancho.jpg`)"
        img-alt="Image"
        overlay
      ></b-card>
      <!-- <b-card class="card " text-variant="white">
        <blockquote class="card-blockquote">
          <p class="titulo">
            {{ $t("gallery.dantenava.orgullo.title") }}
          </p>
          <footer>
            <p class="">
              "{{ $t("gallery.dantenava.orgullo.cont") }}"
            </p>
            <cite class="">- Dante Nava</cite>
          </footer>
        </blockquote>
      </b-card> -->
      
      <b-card
        :img-src="require(`../../assets/fotos/2-ancho.jpg`)"
        img-alt="Image"
        overlay
      >
        <!-- img-top Esto va en lugar de overlay -->
        <!-- <b-card-text>
          This card has supporting text below as a natural lead-in to additional
          content.
        </b-card-text> -->
      </b-card>
      <!-- <b-card
        :img-src="require(`../../assets/fotos/calle.jpg`)"
        img-alt="Image"
        overlay
      ></b-card> -->
    </b-card-group>
  </div>
</template>

<script>
export default {};
</script>

<style>
.gallerycomponent{
  margin: 50px 20px;
}
.headergallery{
  text-align: center;
}
.card {
  background-color: #533e2d;
}
.titulo {
  font-family: "Aladin", cursive;
}
</style>